<script setup lang="ts">
import { useInterestStore } from '~~/src/services/interest';
import { useUserAuthStore } from '~~/src/services/userAuth';

import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { useCouponStore } from '~/services/coupon/store';
import { useMainStore } from '~/services/main';
import { type DesignPopup } from '~/services/main/type';

const route = useRoute();
const couponStore = useCouponStore();
const mainStore = useMainStore();
const interestStore = useInterestStore();
const userAuthStore = useUserAuthStore();

const currentPagePopupList = computed(() => {
  if (route.name === 'index') {
    // main
    return mainStore.mainDesignPupupList;
  } else if (route.name === 'community') {
    // community
    return mainStore.boardDesignPupupList;
  } else if (route.name === 'class') {
    // category
    const myPopupCategoryList = mainStore.categoryDesignPupupList.filter((v) =>
      v.designPopUpCategoryList!.some((vv) => vv.cateSeq == (route.query.cateSeq as any)),
    );

    return myPopupCategoryList;
  }
});

watch(
  () => userAuthStore.user,
  (user) => {
    if (user) {
      mainStore.fetchDesignPopup();
    }
  },
  { immediate: true },
);

function onPopupOpenOneDay(item: DesignPopup) {
  nextTick(() => {
    item.ui_is_open_oneday = !item.ui_is_open_oneday;
    if (item.ui_is_open_oneday) {
      Utility.cacheLastOpenSeq({
        seq: item.designPopupSeq,
        type: 'designPopupSeq',
      });
    } else {
      Utility.removeCacheLastOpenSeq({
        seq: item.designPopupSeq,
        type: 'designPopupSeq',
      });
    }
    item.ui_visible = false;
  });
}

const movePage = (item: DesignPopup) => {
  const {
    public: { frontUrl },
  } = useRuntimeConfig();
  const prevRoute = sessionStorage.getItem('prevRoute');
  const prevPath = sessionStorage.getItem('prevPath');
  Tracker['Click Popup']({
    location: route.name === 'index' ? 'MAIN' : route.name === 'community' ? 'COMMUNITY' : 'CATEGORY',
    popupTitle: currentPagePopupList.value?.[0]?.designPopupTitle,
    popupUrl: currentPagePopupList.value?.[0]?.designPopupLinkUrl,
    path: prevRoute,
    url: `${frontUrl}${prevPath}`,
  });
  Utility.openLink(item.designPopupLinkUrl, item.designPopupLinkType);
};

// 메인 팝업의 모든 큐가 빈 경우, 메인팝업이 모두 닫히는 것으로 간주
watch(
  () => mainStore.getMainPopupQueue,
  (popupQueue) => {
    mainStore.setMainPopupVisible(popupQueue.length > 0);
  },
  {
    immediate: true,
  },
);
const visibleUpdated = (popupItem, isHide) => {
  const currentMainPopupQueue = mainStore.getMainPopupQueue;
  if (isHide) {
    // 닫힘
    mainStore.setMainPopupQueue(currentMainPopupQueue.filter((v) => v.designPopupSeq !== popupItem.designPopupSeq));
  } else {
    // 열림
    mainStore.setMainPopupQueue([...currentMainPopupQueue, popupItem]);
  }
};
</script>

<template>
  <Dialog
    v-for="(item, index) in currentPagePopupList"
    :key="`${item}_${index}`"
    :visible="item.ui_visible && !couponStore.getWelcomePopupVisible && !interestStore.getInterestPopupVisible"
    :modal="item.designPopupFrameType == 'L'"
    class="wb-dialog-main"
    :style="{
      width: `${item.designPopupWidthSize}px`,
      'min-height': '150px',
    }"
    @hide="visibleUpdated(item, true)"
    @show="visibleUpdated(item, false)">
    <div class="wb-dialog-main__contents">
      <img
        :src="
          isMobile
            ? Utility.getOptimized(item.designPopupMobileImageUrl, { q: 80 })
            : Utility.getOptimized(item.designPopupPcImageUrl, { q: 80 })
        "
        alt=""
        loading="lazy"
        @click="movePage(item)" />
    </div>
    <div class="wb-dialog-main__foot">
      <p @click="onPopupOpenOneDay(item)">
        <label :for="`todayClose_${index}`">오늘 하루 보지 않기</label>
      </p>
      <Button icon="pi pi-times" severity="secondary" text rounded @click="item.ui_visible = false">
        <span class="p-button-icon"> </span>
      </Button>
    </div>
  </Dialog>
</template>

<style lang="scss">
.wb-dialog-main {
  min-width: 320px;
  max-width: 1080px;
  overflow: hidden;
  border: 1px solid rgba(9, 30, 66, 0.05);
  border-radius: 8px;
  @media screen and (max-width: $md) {
    max-width: calc(100% - 32px);
  }
  .p-dialog-content {
    padding: 0;
  }
  &__contents {
    cursor: pointer;
  }

  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-top: 0px;
    p {
      display: flex;
      align-items: center;
      cursor: pointer;

      .p-checkbox {
        min-width: 32px;
        width: 32px;
        height: 32px;
      }

      label {
        margin: 10.5px 12px;
        color: #788194;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width: $md) {
          margin: 11.5px 12px;
        }
      }
    }

    button:enabled {
      .p-button-icon {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background: url('/icon/click-area.svg') no-repeat 50% 50%;
      }
      &:hover {
        border-radius: 0px;
        background: unset !important;
      }
    }
  }
}
</style>
